import { Box, Stack, StackProps, SxProps } from '@mui/material'
import { Button, Container, Typography } from '@evocolabs/orkaui'

import React from 'react'
import styles from './styles.module.scss'
import theme from '@/src/theme'

interface BannerProps extends Omit<StackProps, 'content'> {
  index?: number
  upperText?: string
  title?: string
  content?: React.ReactNode
  contentMaxWidth?: number // TODO: figure better solution
  btnText?: string
  btnCTA?: () => void
  bgStyles?: SxProps
  buttonId: string
  mode?: 'light' | 'dark'
}

const Banner = ({
  index,
  upperText,
  title,
  content,
  contentMaxWidth,
  btnText,
  buttonId,
  btnCTA,
  bgStyles,
  children,
  mode = 'light',
  ...restProps
}: BannerProps) => {
  const modeStyles = {
    title: {
      dark: theme.palette.primary[700],
      light: theme.palette.white['main']
    },
    content: {
      dark: theme.palette.primary[700],
      light: theme.palette.white['main']
    },
    button: {
      variant: {
        dark: 'secondary',
        light: 'destructive'
      }
    }
  }
  return (
    <Stack className={styles.banner} {...restProps}>
      <Box pt={{ xs: 6, dl: 0 }} sx={bgStyles} />
      <Box className={styles['banner-container']}>
        {!!children ? (
          children
        ) : (
          <Container
            sx={{
              padding: '40px 16px',
              height: '100%',
              '@media (min-width: 600px) and (max-width: 768px)': {
                padding: '40px 80px'
              },
              '@media (min-width: 769px)': {
                padding: '0px 108px'
              }
            }}
          >
            <Stack
              justifyContent={{ xs: 'none', dl: 'center' }}
              height="100%"
              maxWidth={contentMaxWidth ? `${contentMaxWidth}px` : 'none'}
              marginLeft={index === 0 ? 'auto' : 'none'}
            >
              {upperText && (
                <Typography
                  variant="T1"
                  sx={{ mb: '8px' }}
                  color={modeStyles.title[mode]}
                >
                  {upperText}
                </Typography>
              )}
              {title && (
                <Typography
                  variant="h1"
                  sx={{
                    whiteSpace: 'pre-wrap'
                  }}
                  color={modeStyles.title[mode]}
                >
                  {title}
                </Typography>
              )}
              {content && (
                <Typography
                  variant="body1"
                  marginTop={{ xs: 1, sm: 2 }}
                  as="span"
                  color={modeStyles.content[mode]}
                >
                  {content}
                </Typography>
              )}
              {btnCTA && btnText ? (
                <Button
                  variant={modeStyles.button.variant[mode]}
                  size="large"
                  className={`${styles.CTA} ${styles['banner-CTA']}`}
                  onClick={btnCTA}
                  id={buttonId}
                >
                  {btnText}
                </Button>
              ) : null}
            </Stack>
          </Container>
        )}
      </Box>
    </Stack>
  )
}

export default Banner
