import 'swiper/css'
import 'swiper/css/pagination'

import { Autoplay, Navigation, Pagination } from 'swiper'
import { ResponsiveBg, getImage, getResponsiveStyles } from './utils'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useRef, useState } from 'react'

import Banner from './Banner'
import { Box } from '@mui/material'
import ChevronLeft24 from '@evocolabs/icons/lib/ChevronLeft24'
import ChevronRight24 from '@evocolabs/icons/lib/ChevronRight24'
import { IconButton } from '@evocolabs/orkaui'
import React from 'react'
import styles from './styles.module.scss'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useRouter } from 'next/router'

const bannerProps = {
  upperText: '',
  title: 'Crystal Clear Hearing\nwith Orka Two',
  content:
    'Introducing next-gen hearing aids with premium technology',
  btnText: 'Explore Orka Two'
}

const PRODUCT_PATH = '/OrkaTwo'
const RESOURCE_FILE_DEFAULT = 'homepage-hero-section-spring-1'

const getMediaStyles = (resource_file_name: string): ResponsiveBg => {
  return {
    pc: {
      src: getImage(resource_file_name + '.webp', 'PC'),
      coverRatio: 696 / 1440
    },
    mobile: {
      src: getImage(resource_file_name + '.webp', 'Mobile'),
      coverRatio: 1128 / 780
    }
  }
}

export default function Welcome() {
  const router = useRouter()

  const defaultBannerCTA = () => {
    router.push(PRODUCT_PATH)
  }

  const bannerSource = [
    {
      bannerContent: bannerProps,
      callToAction: defaultBannerCTA,
      resourceFileName: RESOURCE_FILE_DEFAULT,
      buttonId: RESOURCE_FILE_DEFAULT
    }
  ]
  const swiperRef = useRef(null)
  const isMobile = useMediaQuery('(max-width:1024px)')
  const [currentSlide, setCurrentSlide] = useState(0)
  const lastRealIndexSlide = useRef(0)
  const swiperKey = isMobile ? 'mobile-swiper' : 'desktop-swiper'
  const pagination = {
    clickable: true,
    horizontalClass: styles['swiper-horizontal'],
    bulletClass: styles['swiper-pagination-bullet'],
    bulletActiveClass: styles['swiper-pagination-bullet-active']
  }

  //controller for swiper
  const goToNextSlide = () => {
    if (swiperRef?.current?.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const goToPrevSlide = () => {
    if (swiperRef?.current?.swiper) {
      swiperRef.current.swiper.slidePrev()
    }
  }

  const handleSlideChange = (swiper: { realIndex: number }) => {
    const newRealIndexSlide = swiper.realIndex
    if (newRealIndexSlide !== lastRealIndexSlide.current) {
      lastRealIndexSlide.current = newRealIndexSlide
      setCurrentSlide(newRealIndexSlide)
    }
  }

  const swiperButtonNodes = (
    <Box
      id="swiper-arrow-container"
      className={styles['swiper-arrow-container']}
    >
      <IconButton
        style={{ zIndex: 11 }}
        onClick={goToPrevSlide}
        disabled={currentSlide === 0}
      >
        <ChevronLeft24
          forceStroke={currentSlide === 0 ? '#FFFFFF59' : '#ffffff'}
        />
      </IconButton>
      <IconButton
        style={{ zIndex: 11 }}
        onClick={goToNextSlide}
        disabled={currentSlide === 1}
      >
        <ChevronRight24
          forceStroke={currentSlide === 1 ? '#FFFFFF59' : '#ffffff'}
        />
      </IconButton>
    </Box>
  )

  const disableSwiper = bannerSource.length === 1

  const bannerNodes = bannerSource.map((banner, index) => {
    const { bannerContent, callToAction, resourceFileName, buttonId } = banner
    const mediaStyles = getMediaStyles(resourceFileName)
    const responsiveStyles = getResponsiveStyles(mediaStyles)

    return (
      <React.Fragment key={bannerContent.title + index}>
        <Banner
          index={index}
          upperText={bannerContent.upperText}
          buttonId={buttonId}
          {...bannerContent}
          btnCTA={callToAction}
          bgStyles={{ ...responsiveStyles }}
          contentMaxWidth={550}
          mode={index === 0 ? 'dark' : 'light'}
          sx={{ display: { xs: 'none', sm: 'flex' } }}
        />
        <Banner
          index={index}
          upperText={bannerContent.upperText}
          buttonId={buttonId}
          {...bannerContent}
          btnCTA={callToAction}
          bgStyles={{ ...responsiveStyles }}
          contentMaxWidth={550}
          mode={'light'}
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        />
      </React.Fragment>
    )
  })

  return (
    <>
      {disableSwiper ? (
        bannerNodes
      ) : (
        <Swiper
          key={swiperKey}
          onSlideChange={handleSlideChange}
          ref={swiperRef}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false
          }}
          allowTouchMove={isMobile}
          pagination={pagination}
          speed={500}
          modules={[Autoplay, Pagination, Navigation]}
          style={{ width: '100vw', transform: 'none', zIndex: 99 }}
        >
          {bannerNodes.map((bannerNode) => (
            <SwiperSlide key={bannerNode.key}>{bannerNode}</SwiperSlide>
          ))}
          {swiperButtonNodes}
        </Swiper>
      )}
    </>
  )
}
