import { Box } from '@mui/material'
import { useEffect } from 'react'
import ScriptHelper from '@/src/utils/ScriptHelper'

export default ({
  callback = () => void 0,
  sx = {},
  textAlign = 'center',
  width = '100%',
  height = '24px',
  theme = 'light',
  minReviewCount = 10,
  templateId = '5419b6a8b0d04a076446a9ad'
}) => {
  useEffect(() => {
    ScriptHelper({
      src: `//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js?${encodeURIComponent(
        `styleAlignment=${textAlign}&styleWidth=${width}&styleHeight=${height}&theme=${theme}&minReviewCount=${minReviewCount}`
      )}`,
      callback: () => {
        callback()
      }
    })
  }, [])

  return (
    <Box textAlign="center" sx={sx} onClick={callback}>
      <div
        data-locale="en-US"
        data-template-id={templateId}
        data-businessunit-id="63c7a5f49322c7323cf3d0e6"
        data-style-height={height}
        data-style-width="100%"
        data-theme="light"
        data-min-review-count="10"
        data-style-alignment={textAlign}
        className="trustpilot-widget"
      >
        <div />
      </div>
    </Box>
  )
}
