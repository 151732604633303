import { Box } from '@mui/material'
import { SwiperSlide } from 'swiper/react'
import { Typography } from '@evocolabs/orkaui'
import Link from 'next/link'
import joinCDN from '@/src/utils/CDN'
import styles from './styles.module.scss'
import SwipeableGroup from './SwipeableGroup'
import { type News, newsList } from './data'

function Card({ id, title, content, date, url }: News) {
  return (
    <Link href={url} rel="noopener noreferrer" target="_blank">
      <Box className={`${styles.card} ${styles.card__news}`}>
        <Box>
          <img
            src={joinCDN(`/website/homepage/${id.toLowerCase()}.svg`)}
            height="40"
            alt={id}
          />
          <Typography variant="subtitle1" className={styles.title}>
            {title}
          </Typography>
          <Typography variant="body3" className={styles.content} as="div">
            {content}
          </Typography>
        </Box>
        <Typography variant="body4" mt={{ xs: 3, dl: 4 }}>
          {date}
        </Typography>
      </Box>
    </Link>
  )
}
function News() {
  return (
    <SwipeableGroup spaceBetween={24}>
      {newsList.map((data) => (
        <SwiperSlide key={data.id} style={{ height: 'auto' }}>
          <Card {...data} />
        </SwiperSlide>
      ))}
    </SwipeableGroup>
  )
}

export default News
