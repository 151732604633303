import { Button, Container, Typography } from '@evocolabs/orkaui'
import { ContentTitle, SectionTitle } from '../title'
import { Grid, Stack } from '@mui/material'

import React from 'react'
import { getImage } from './utils'
import styles from './styles.module.scss'
import { useRouter } from 'next/router'

export interface Professional {
  src: string
  title: string
  academicTitle: string
  intro: string
}

const PROF_SECTION_TITLE = 'Expert Care From Orka Professionals'
const CTA_TEXT = 'Learn More about OrkaCare'

const professionals: Professional[] = [
  {
    src: 'alexi.jpg',
    title: 'Meet Dr. Alexi Silance',
    academicTitle: 'Doctor of Audiology (AuD), DNM',
    intro:
      'With over 30 years experience as an audiologist, Dr. Alexi has a passion for improving communication abilities for those with hearing loss. Her focus lies heavily on providing education about hearing aids and tinnitus management.'
  }
]

const Professionals = (): JSX.Element => {
  const router = useRouter()
  return (
    <Container className={styles['prof-container']}>
      <Stack
        spacing={{ xs: 4, dl: 5 }}
        maxWidth={{ xs: 400, dl: 960 }}
        margin={'0 auto'}
        alignItems="center"
      >
        <SectionTitle>{PROF_SECTION_TITLE}</SectionTitle>
        {professionals.map(({ src, title, academicTitle, intro }) => (
          <Grid
            direction={{ xs: 'column', dl: 'row' }}
            alignItems="center"
            container
            key={title}
          >
            <Grid
              item
              xs
              sx={{
                '& > img:first-of-type': {
                  display: {
                    xs: 'inline',
                    md: 'none'
                  }
                },
                '& > img:last-of-type': {
                  display: {
                    xs: 'none',
                    md: 'inline'
                  }
                }
              }}
            >
              <img
                src={getImage(src, true)}
                className={styles.image}
                loading="lazy"
                alt="Alexi"
              />
              <img
                src={getImage(src, false)}
                className={styles.image}
                loading="lazy"
                alt="Alexi"
              />
            </Grid>
            <Grid item xs ml={{ xs: 0, dl: 5 }} mt={{ xs: 4, dl: 0 }}>
              <Stack
                alignItems={{ xs: 'center', dl: 'flex-start' }}
                spacing={{ xs: 1, dl: 2 }}
                flex={1}
              >
                <ContentTitle>{title}</ContentTitle>
                <Typography
                  variant="Body2"
                  className={styles['prof-content-text']}
                >
                  {academicTitle}
                </Typography>
                <Typography
                  variant="body2"
                  className={styles['prof-content-text']}
                >
                  {intro}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        ))}
        <Button
          onClick={() => router.push('OrkaCare')}
          id="homepage-redirect-orka-care"
        >
          {CTA_TEXT}
        </Button>
      </Stack>
    </Container>
  )
}

export default Professionals
