import { Box, Dialog, Stack, BoxProps } from '@mui/material'
import { Typography } from '@evocolabs/orkaui'
import Image from 'next/image'
import Close24 from '@evocolabs/icons/lib/Close24'
import theme from '@/src/theme'
import { getImage } from '../utils'
import styles from './styles.module.scss'

interface FounderStoryProps {
  open: boolean
  onClose: () => void
}

const data = {
  title: 'Why Orka Was Created',
  author: 'Ben Sun',
  authorTitle: 'Co-Founder & CEO',
  intro:
    'I noticed my grandma was struggling to stay involved in conversations. I couldn’t stop thinking about it. Why on earth were there not hearing aids with modern consumer-tech quality design?',
  content: `In 2018 I was in my third year designing products for Apple. I could envision a future in Cupertino. Who knows, maybe I could be a part of the next iPad or Apple Watch. Then, over the holidays visited my grandma. Her hearing had been in slow decline for decades, but I noticed she was struggling to stay involved in conversations. I figured I'd reboot her hearing aids and fix the issue - after all, technology is what I do. After an hour of tinkering, I realized this wasn't a bug; her hearing aids simply were not capable of managing background noise, and the design was, well, not Apple. I couldn't stop thinking about it. Why on earth were there not hearing aids with modern consumer-tech quality design? And how could we fix this background noise issue? Before long, my thoughts turned to sketches, and eventually, I started talking to my friends. Xinke had a cochlear implant and a passion for making the space more accessible. Chauncey was on the AI team at Facebook, and Linkai was at Siemens, a company with a long history in hearing health. Before long, we were pulling late nights, building prototypes in my kitchen.

  Over the next few years, our prototypes and the technology inside matured. We won awards and started seeing impressive results in background noise: We were recognized with the Red Dot Award for Product Design, and the honor of being a CES InnovationAwards honoree for our innovative technology and human-centered design. This journey also led us to secure awards in hearingclarity enhancement algorithm challenges hosted by IEEE. Good progress. But now we had a big decision to make.

  Should we go B2B and sell to local clinics, who would in turn sell to customers? Or make our products directly available to customers?

  It wasn't a simple decision! The majority of hearing aids in the U.S. are fit in a clinic, but a meaningful minority are moving online for lower pricing and better access to ongoing support. Ultimately, we turned to our community, and their answer was clear. Our customers wanted direct access to our products at a price below the national average ($4,500 / pair), but they also wanted an audiologist they could access at any time - from anywhere.

  In August 2023, we brought our first commercially available product to the market. Orka Two is a rechargeable, Bluetooth-enabled hearing aid with lifetime doctor care and incredibly strong underlying background noise management. Orka Two is also beautiful, with all the little touches that make me love product design. We transformed the magnetic recharge case into a remote control for easy and discreet volume changes. We built in wear detection so that your hearing aids turn off when you aren't wearing them. We also made the hearing aids fully Android compatible and built-in automatic device switching for folks who stream through both a laptop and a phone. Over the last few months since our launch, we've received some incredible feedback from customers.

  The best part? We built our hearing aids with the ability to update them over-the-air. That means our customers don't have to wait for the next generation to benefit from our latest improvements. We can passively push those improvements directly to our customers' hearing aids. As more customers wear Orka, our technology in the toughest situations will get smarter and better over time.

  If you are an existing Orka customer, we're so glad to have you on board. If you want to give us a try, give us a call or fill out the "Am I a Candidate" form at the top of the site.

  Here's to better hearing for my grandma, for you, and for the people you love.
  `
}

function Avatar() {
  return (
    <Image
      src={getImage('ben_square.jpg', 'PC')}
      alt="founder avatar"
      width={176}
      height={176}
      style={{
        borderRadius: '8px'
      }}
    />
  )
}

function AuthorDetails(props: BoxProps) {
  const { author, authorTitle } = data
  return (
    <Box {...props}>
      <Typography variant="t1">{author}</Typography>
      <Typography variant="subtitle2">{authorTitle}</Typography>
    </Box>
  )
}

function MobileProfile() {
  return (
    <Stack display={{ xs: 'flex', dl: 'none' }} gap={1}>
      <Box display="flex" gap="12px">
        <Avatar />
        <AuthorDetails alignSelf="flex-end" />
      </Box>
      <Box display={{ xs: 'none', sm: 'flex' }} flexDirection="column">
        <Typography variant="h2" className={styles['quotation-mark__mobile']}>
          “
        </Typography>
        <Typography variant="body3" px={2}>
          {data.intro}
        </Typography>
        <Typography
          variant="h2"
          className={styles['quotation-mark__mobile']}
          as="span"
          alignSelf="flex-end"
        >
          ”
        </Typography>
      </Box>
    </Stack>
  )
}

function DesktopProfile() {
  return (
    <Box display={{ xs: 'none', dl: 'flex' }} gap={3}>
      <Avatar />
      <Stack paddingY={1} justifyContent="space-between">
        <AuthorDetails />
        <Box display="flex">
          <Typography
            variant="h1"
            as="span"
            sx={{ transform: 'translateY(-2px)' }}
          >
            “
          </Typography>
          <Typography variant="body2" ml={1}>
            {data.intro}
          </Typography>
          <Typography
            variant="h1"
            className={styles['quotation-mark']}
            as="span"
            alignSelf="flex-end"
          >
            ”
          </Typography>
        </Box>
      </Stack>
    </Box>
  )
}

function FounderStory({ open, onClose }: FounderStoryProps) {
  const { title, content } = data
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
          margin: '16px'
        }
      }}
      maxWidth="md"
    >
      <Box className={styles['founder-story-intro']}>
        <Typography
          variant="h2"
          sx={{
            fontSize: {
              xs: '24px',
              dl: '40px'
            },
            lineHeight: {
              xs: '32px',
              dl: '42px'
            }
          }}
        >
          {title}
        </Typography>
        <Box className={styles['founder-story-intro-content']}>
          <DesktopProfile />
          <MobileProfile />
        </Box>
      </Box>
      <Box className={styles['founder-story-content']}>
        <Typography variant="body4" sx={{ lineHeight: '26px' }}>
          {content}
        </Typography>
      </Box>
      <Box className={styles.close} onClick={onClose}>
        <Close24 replaceColor={[['#26201F', theme.palette.grey[100]]]} />
      </Box>
    </Dialog>
  )
}

export default FounderStory
