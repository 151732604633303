import { Box } from '@mui/material'
import { Typography } from '@evocolabs/orkaui'
import { SwiperSlide } from 'swiper/react'
import TrustpilotStars from '../../TrustpilotStars'
import styles from './styles.module.scss'

import SwipeableGroup from './SwipeableGroup'
import { reviewList } from './data'

function Card({ title, subTitle, content, author, stars, setPopData }) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      width={294}
      height={360}
      className={`${styles.card} ${styles.card__reviews}`}
    >
      <TrustpilotStars scope={stars} />
      <Typography
        variant="t1"
        sx={{
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: '1',
          overflow: 'hidden',
          mt: '16px'
        }}
      >
        {title}
      </Typography>
      <Box>
        <Typography
          variant="body3"
          component="span"
          sx={{
            display: 'inline'
          }}
        >
          {subTitle}
        </Typography>
        <Typography
          variant="link"
          color="#506A8A"
          component="span"
          sx={{ display: 'inline' }}
          onClick={() => {
            const fn = () =>
              setPopData({
                open: false,
                title,
                subTitle,
                content,
                author,
                stars,
                callback: fn
              })

            setPopData({
              open: true,
              title,
              subTitle,
              content,
              author,
              stars,
              callback: fn
            })
          }}
        >
          View More
        </Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        width="100%"
        mt="16px"
        justifyContent="space-between"
      >
        <Typography variant="Body3">{author}</Typography>
      </Box>
    </Box>
  )
}

export default function Reviews({ setPopData }) {
  return (
    <SwipeableGroup>
      {reviewList.list.map((data) => (
        <SwiperSlide key={data.id}>
          <Card {...{ ...data, setPopData }} />
        </SwiperSlide>
      ))}
    </SwipeableGroup>
  )
}
