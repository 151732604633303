import 'swiper/css'
import 'swiper/css/virtual'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/effect-cube'
import 'swiper/css/pagination'

import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Autoplay } from 'swiper'
import { Box } from '@mui/material'
import joinCDN from '@/src/utils/CDN'

const data = [
  {
    img: joinCDN('/website/homepage/soundly.svg'),
    imgM: joinCDN('/website/homepage/soundly-m.svg'),
    link: 'https://www.soundly.com/product/orka-two'
  },
  {
    img: joinCDN('/website/homepage/hearing.svg'),
    imgM: joinCDN('/website/homepage/hearing-m.svg'),
    link: 'https://hearinghealthmatters.org/hearing-technologies/2023/orka-two-hearing-aids-launch/'
  },
  {
    img: joinCDN('/website/homepage/forbes.svg'),
    imgM: joinCDN('/website/homepage/forbes-m.svg'),
    link: 'https://www.forbes.com/sites/stevenaquino/2023/01/20/hearing-health-startup-orka-wants-to-make-hearing-aids-as-cool-as-airpods-with-its-orka-one-device/?sh=65f8144631a2'
  },
  {
    img: joinCDN('/website/homepage/hearingreview.svg'),
    imgM: joinCDN('/website/homepage/hearingreview-m.svg'),
    link: 'https://hearingreview.com/hearing-products/hearing-aids/orka-unveils-hearing-aid-featuring-next-gen-chip-tech'
  },
  {
    img: joinCDN('/website/homepage/yd.svg'),
    imgM: joinCDN('/website/homepage/yd-m.svg'),
    link: 'https://www.yankodesign.com/2023/09/06/orka-two-makes-it-natural-for-users-to-wear-and-use-these-minimal-hearing-aids/?utm_source=feedly&utm_medium=rss&utm_campaign=orka-two-makes-it-natural-for-users-to-wear-and-use-these-minimal-hearing-aids'
  },
  {
    img: joinCDN('/website/homepage/hearingtracker.svg'),
    imgM: joinCDN('/website/homepage/hearingtracker-m.svg'),
    link: 'https://www.hearingtracker.com/news/orka-two-hearing-aid-launched-with-general-chip-technology-ai-denoise-bluetooth-and-personalized-fitting'
  },
  {
    img: joinCDN('/website/homepage/ces.svg'),
    imgM: joinCDN('/website/homepage/ces-m.svg'),
    link: 'https://www.prnewswire.com/news-releases/orka-named-as-ces-2021-innovation-awards-honoree-301208818.html'
  },
  {
    img: joinCDN('/website/homepage/reddotwinner.svg'),
    imgM: joinCDN('/website/homepage/reddotwinner-m.svg'),
    link: 'https://www.prnewswire.com/news-releases/redefining-hearing-aids-orka-receives-red-dot-recognition-301336185.html#:~:text=CHICAGO%2C%20July%2019%2C%202021%20%2F,CES%202021%20Innovation%20Awards%20Honoree.'
  },
  {
    img: joinCDN('/website/homepage/wired.svg'),
    imgM: joinCDN('/website/homepage/wired-m.svg'),
    link: 'https://www.wired.com/review/orka-two-hearing-aids/'},
]
export default function MechanismList() {
  const swiperRef = useRef() as any

  return (
    <Box
      bgcolor="#EFF1F5"
      width={'100vw'}
      display="flex"
      justifyContent="center"
      alignContent="center"
      sx={{
        '& .swiper': {
          margin: { xs: '32px 0', md: '40px 0' },
          maxWidth: '1440px'
        },
        '& .swiper-wrapper': {
          alignItems: 'center',
          transitionTimingFunction: 'linear !important',
          cursor: 'auto !important'
        },
        '& .mechanism-list-swiper-slide': {
          width: 'auto !important',
          marginRight: '0 !important',
          display: 'flex',
          justifyContent: 'center'
        },
        '& .mechanism-list-swiper-slide + .mechanism-list-swiper-slide': {
          marginLeft: { xs: '32px', md: '80px' }
        },
        '& .mechanism-list-swiper-slide-img': {
          '&.mobile': {
            display: { xs: 'inline', md: 'none' },
            width: '200px',
            height: '45px'
          },
          display: { xs: 'none', md: 'inline' },
          width: '300px',
          height: '80px',
          cursor: 'pointer'
        }
      }}
    >
      <Swiper
        ref={swiperRef}
        loop={true}
        style={{
          width: '100%'
        }}
        spaceBetween={0}
        slidesPerView="auto"
        allowTouchMove={false}
        navigation={{
          prevEl: null,
          nextEl: null
        }}
        speed={5000}
        autoplay={{
          delay: 1,
          disableOnInteraction: false,
          pauseOnMouseEnter: false
        }}
        modules={[Autoplay]}
        grabCursor={false}
        pagination={false}
      >
        {data.map((data, index) => (
          <SwiperSlide
            key={data.img.match(/(\w+(-\w+)*)\.\w+$/)?.[1] || index}
            className="mechanism-list-swiper-slide"
          >
            <a
              href={data.link}
              target="_blank"
              style={{
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
              aria-label={data.link}
            >
              <img
                src={data.img}
                alt="orkaTwo"
                loading="lazy"
                onLoad={() => swiperRef.current.swiper.autoplay.start()}
                className="mechanism-list-swiper-slide-img"
              />
              <img
                src={data.imgM}
                alt="orkaTwo"
                loading="lazy"
                onLoad={() => swiperRef.current.swiper.autoplay.start()}
                className="mechanism-list-swiper-slide-img mobile"
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
