import React, { useState, useRef } from 'react'
import { Box } from '@mui/material'
import { Swiper } from 'swiper/react'
import { Navigation } from 'swiper'

import { ArrowBack, ArrowForward } from '@/src/components/Icon/Arrow'
import theme from '@/src/theme'
import styles from './styles.module.scss'

interface SwipeableGroupProps {
  children: React.ReactNode
  maxWidth?: number
  spaceBetween?: number
}

function SwipeableGroup({
  children,
  maxWidth = 1224,
  spaceBetween = 16
}: SwipeableGroupProps) {
  const [index, setIndex] = useState(0)

  const swiperRef = useRef<{ swiper }>()
  const goto = (isNext = true) => {
    if (isNext) {
      swiperRef?.current?.swiper?.slideNext()
    } else {
      swiperRef?.current?.swiper?.slidePrev()
    }
  }

  const slideChange = () => {
    const { realIndex } = swiperRef.current.swiper
    setIndex(realIndex)
  }

  const iconProps = {
    strokeWidth: '1.5',
    stroke: 'white',
    fill: theme.palette.primary[600],
    hoverFill: theme.palette.primary[500],
    mathMode: true
  }

  return (
    <Box
      position="relative"
      sx={{
        ' .swiper-pagination': { position: 'relative' },
        ' .swiper-pagination-bullet': {
          border: 'solid 2px #506A8A',
          opacity: '1',
          width: '10px',
          height: '10px',
          backgroundColor: 'transparent'
        },
        '& .swiper-wrapper': {
          cursor: 'auto !important'
        },
        ' .swiper-pagination-bullet-active': { backgroundColor: '#506A8A' },
        '>.swiper>.swiper-wrapper>.swiper-slide': {
          backgroundColor: 'transparent',
          width: 'auto'
        }
      }}
    >
      <Swiper
        ref={swiperRef}
        id="about-swiper-container"
        style={{
          width: '100%',
          maxWidth
        }}
        spaceBetween={spaceBetween}
        slidesPerView="auto"
        navigation={{
          prevEl: null,
          nextEl: null
        }}
        onSlideChange={slideChange}
        pagination
        modules={[Navigation]}
        grabCursor={false}
      >
        {children}
      </Swiper>
      <Box
        id="about-swiper-custom-navigation"
        maxWidth={maxWidth}
        className={styles['swipeable-group-navigation']}
      >
        <Box
          className="custom-prev-btn"
          onClick={() => goto(false)}
          sx={{
            pointerEvents: 'all',
            opacity: index < 1 ? 0 : 1,
            transform: { xs: 'translateX(16px)', dl: 'translateX(-50%)' },
            alignSelf: 'center'
          }}
        >
          <ArrowBack {...iconProps} />
        </Box>
        <Box
          className="custom-next-btn"
          onClick={() => goto()}
          sx={{
            pointerEvents: 'all',
            opacity: index > 0 ? 0 : 1,
            transform: { xs: 'translateX(-16px)', dl: 'translateX(50%)' },
            alignSelf: 'center'
          }}
        >
          <ArrowForward {...iconProps} />
        </Box>
      </Box>
    </Box>
  )
}

export default SwipeableGroup
