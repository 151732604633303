import {
  About,
  Difference,
  MechanismList,
  Mission,
  Welcome
} from '@/src/components/HomePage'

import { Box } from '@mui/material'
import Footer from '@/src/components/Footer/Footer2'
import Header from '@/src/components/HeaderV2'
import React from 'react'

const CacheClickSessionStorageKey = 'homepage-cache-click'
const cacheClick = (id: string) => {
  sessionStorage.setItem(CacheClickSessionStorageKey, id)
}
const clearCacheClick = () => {
  sessionStorage.removeItem(CacheClickSessionStorageKey)
}
const jumpToCacheClick = () => {
  const id = sessionStorage.getItem(CacheClickSessionStorageKey)
  if (id) {
    const element = document.getElementById(id)
    if (element) {
      element.scrollIntoView()
      clearCacheClick()
    }
  }
}

export default function Home() {
  React.useEffect(() => {
    jumpToCacheClick()
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        '& > div > .orkaui-modal-root > .orkaui-modal-content': {
          marginTop: { xs: '16px', md: 0 },
          padding: {
            xs: '48px 16px 12px',
            md: '64px 24px 24px'
          },
          maxHeight: '90vh'
        }
      }}
    >
      <Header />
      <Welcome />
      <MechanismList />
      <Difference />
      <Mission />
      <About />
      <Footer />
    </Box>
  )
}

export { cacheClick }
