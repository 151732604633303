import { styled } from '@mui/material/styles'

const ArrowForward = ({
  disable = false,
  fill = '#E5E4E3',
  stroke = '#26201F',
  strokeWidth = '1',
  width = 40,
  height = 40,
  disableFill = '#F3F3F3',
  disableStroke = '#B8B6B5',
  hoverFill = '#B8B6B5',
  hoverOpacity = 1,
  mathMode = false
}) => {
  const Ad = styled('svg')`
    &:hover rect {
      ${disable ? '' : `fill: ${hoverFill};`}
      opacity: ${hoverOpacity};
    }
  `
  return (
    <Ad
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ cursor: disable ? 'auto' : 'pointer' }}
    >
      <rect width={width} height={height} rx="20" fill={disable ? disableFill : fill} />
      <path
        style={{ display: mathMode ? 'none' : 'inline' }}
        d="M12 20.1111L26.9945 20.1111"
        stroke={disable ? disableStroke : stroke}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        style={{ transform: mathMode ? 'translateX(-3px)' : 'none' }}
        d="M20.8857 13L28.0001 20.1917L20.8857 27"
        stroke={disable ? disableStroke : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Ad>
  )
}
const ArrowBack = ({
  disable = false,
  fill = '#E5E4E3',
  stroke = '#26201F',
  strokeWidth = '1',
  width = 40,
  height = 40,
  disableFill = '#F3F3F3',
  disableStroke = '#B8B6B5',
  hoverFill = '#B8B6B5',
  hoverOpacity = 1,
  mathMode = false
}) => {
  const Ad = styled('svg')`
    &:hover rect {
      ${disable ? '' : `fill: ${hoverFill};`}
      opacity: ${hoverOpacity};
    }
  `
  return (
    <Ad
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: 'rotate(180deg)',
        cursor: disable ? 'auto' : 'pointer'
      }}
    >
      <rect width={width} height={height} rx="20" fill={disable ? disableFill : fill} />
      <path
        style={{ display: mathMode ? 'none' : 'inline' }}
        d="M12 20.1111L26.9945 20.1111"
        stroke={disable ? disableStroke : stroke}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        style={{ transform: mathMode ? 'translateX(-3px)' : 'none' }}
        d="M20.8857 13L28.0001 20.1917L20.8857 27"
        stroke={disable ? disableStroke : stroke}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Ad>
  )
}

export { ArrowForward, ArrowBack }
