import { Box, Stack } from '@mui/material'
import { ContentTitle, SectionTitle } from '../../title'

import { Container } from '@evocolabs/orkaui'
import News from './News'
import PopupWindows from './PopupWindow'
import Reviews from './Reviews'
import Trustpilot from '@/src/components/Trustpilot'
import theme from '@/src/theme'
import { useState } from 'react'

const data = {
  title: 'What Others Say About Orka',
  news: {
    title: 'Orka in the News'
  },
  reviews: {
    title: 'Orka in User Reviews'
  }
}

function About() {
  const [popData, setPopData] = useState({
    open: false,
    title: '',
    subTitle: '',
    stars: 5,
    author: '',
    callback: () => undefined
  })

  return (
    <Box bgcolor={theme.palette.primary[100]} width="100%">
      <Container
        id="homepage-about"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: { xs: 4, dl: 5 }
        }}
      >
        <SectionTitle p={{ xs: '0 16px', md: '0 16px' }} alignSelf="center">
          {data.title}
        </SectionTitle>
        <Stack gap={{ xs: 1, dl: 3 }} maxWidth={1224}>
          <ContentTitle textAlign="left">{data.news.title}</ContentTitle>
          <News />
        </Stack>
        <Stack gap={{ xs: 1, dl: 3 }} maxWidth={1224}>
          <ContentTitle textAlign="left">{data.reviews.title}</ContentTitle>
          <Reviews setPopData={setPopData} />
          <Trustpilot sx={{ mt: { xs: 1, dl: 0 } }} />
        </Stack>
        <PopupWindows {...popData} />
      </Container>
    </Box>
  )
}

export default About
