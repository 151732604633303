import { Box } from '@mui/material'
import { Button, Typography } from '@evocolabs/orkaui'
import { ImageCard, ImageCardProps } from '../../ImageCard'
import styles from './styles.module.scss'
import { getImage } from '../utils'
import { generateId } from '../../HeaderV2/utils'

export interface MissionCardProps extends Omit<ImageCardProps, 'content'> {
  content: string
  tag: string
  ctaText: string
  onClick: () => void
}

function MissionCard({
  title,
  src,
  content,
  tag,
  ctaText,
  onClick,
  ...restProps
}: MissionCardProps) {
  return (
    <ImageCard
      src={getImage(src, 'PC')}
      spacing={0}
      title={title}
      className={styles['mission-card']}
      imageProps={{
        style: {
          borderRadius: '12px 12px 0 0'
        }
      }}
      {...restProps}
    >
      <Box className={styles['mission-card-content']}>
        <Box className={styles['mission-card-tag']}>
          <Typography variant="subtitle1">{tag}</Typography>
        </Box>
        <Typography variant="title1">{title}</Typography>
        <Typography variant="body2">{content}</Typography>
        <Button
          variant="destructive"
          onClick={onClick}
          id={`homepage-${generateId(ctaText)}`}
        >
          {ctaText}
        </Button>
      </Box>
    </ImageCard>
  )
}

export default MissionCard
