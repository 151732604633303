export interface News {
  id: string
  title: string
  content: string
  date: string
  url: string // news source
}

export const newsList: News[] = [
  {
    id: 'forbes',
    title:
      'Orka Co-Founders Ben Sun, Xinke Liu Talk Next Generation Hearing Aids In New Interview',
    content:
      'The company truly does have altruism at the core of its mission in helping its customers hear better... The whole stack, so to speak, was meticulously considered for the betterment of users.',
    date: 'Nov 08, 2023',
    url: 'https://www.forbes.com/sites/stevenaquino/2023/11/08/orka-co-founders-ben-sun-xinke-liu-talk-next-generation-hearing-aids-in-new-interview/?sh=3c032bf87442'
  },
  {
    id: 'wired',
    title: 'Review: Orka Two Hearing Aids',
    content: 'They’ll cost you, but these hearing aids have an “AI” tuning that’s actually helpful.',
    url: 'https://www.wired.com/review/orka-two-hearing-aids/',
    date: 'May 04 2024'
  },
  {
    id: 'hearingTracker',
    title:
      'Orka Two Hearing Aid Launched with General Chip and AI DeNoise Technology, Bluetooth, and Personalized Fitting',
    content:
      'Crafted from the ground up and utilizing proprietary technology that distinguishes it from generic white-labeled products on the market, Orka Two is said to combine state-of-the-art attributes, AI-driven noise reduction, and unmatched processing power.',
    date: 'Aug 15, 2023',
    url: 'https://www.hearingtracker.com/news/orka-two-hearing-aid-launched-with-general-chip-technology-ai-denoise-bluetooth-and-personalized-fitting'
  },
  {
    id: 'teams',
    title: 'Orka: Turning Challenges into Opportunities',
    content: `Orka's unique vision and task at hand were not just about the product's look, but about the impact it will have on users' lives.`,
    date: '2023',
    url: 'https://www.teamsdesign.com/en/journal/orka-turning-challenges-into-opportunities'
  },
  {
    id: 'yd',
    title:
      'Orka Two Makes It Natural for Users to Wear and Use These Minimal Hearing Aids',
    content:
      'Orka Two doesn’t seem to want to complicate things for the user but at the same time brings several updated technology features to make life easier for those with hearing problems.',
    url: 'https://www.yankodesign.com/2023/09/06/orka-two-makes-it-natural-for-users-to-wear-and-use-these-minimal-hearing-aids/?utm_source=feedly&utm_medium=rss&utm_campaign=orka-two-makes-it-natural-for-users-to-wear-and-use-these-minimal-hearing-aids',
    date: 'Sep 06 2023'
  }
]

export const reviewList = {
  title: 'See What Others Say About Their Orka Journey',
  list: [
    {
      title: `These are great hearing aids`,
      subTitle: `These are great hearing aids! Before I got my Orkas I could not even participate in conversations in crowded restaurants. It was like sitting alone with a wall of noise. Now, even in the noisiest environment I can set the profile to the conversation  `,
      content: `These are great hearing aids! Before I got my Orkas I could not even participate in conversations in crowded restaurants. It was like sitting alone with a wall of noise. Now, even in the noisiest environment I can set the profile to "In Noise" and join in to the conversation. The fact that I can hear sounds I've been missing for years, is an added bonus. Great, responsive customer service and the remote sessions with the audiologists have been a joy.`,
      author: `Allen`,
      stars: 5,
      id: '1'
    },
    {
      title: `Of all the hearing aids I have tested in the past Orka has the best look, clarity and proformance of some of the most expensive hearing aids on the market.`,
      subTitle: `Being a retired 73 year old design director, I would like to make some design observations about the look and design of Orka hearing aids.When you receive your package you notice the beautiful box that it comes in. The silver leaf Orka  `,
      content: `Being a retired 73 year old design director, I would like to make some design observations about the look and design of Orka hearing aids.When you receive your package you notice the beautiful box that it comes in. The silver leaf Orka logo is a beautiful high end touch to the box. The magnetic clasp of the packaging also creates a high-end look! The trays that hold the manuals and the charging case & accessories remind me of a beautiful Japanese Bendo box.From the modern look of the hearing aids themselves to the minimalist ecosystem of the App. The beautiful claymation like images of a husband and wife with family. It's so beautiful and refreshing in imagery that it makes you stand out from the rest of the competition.Now we come to the AI hearing aids themselves. Of all the hearing aids I have tested in the past Orka has the best look, clarity and performance of some of the most expensive hearing aids on the market. Your team should take a bowl...Best Wishes and please pass this along to your upper management,David Cochran`,
      author: `netartifacts`,
      stars: 5,
      id: '2'
    },
    {
      title: `I love my Orka hearing aids`,
      subTitle: `I love my Orka hearing aids. They have made a tremendous improvement in the clarity of people's voices, especially in noisy environments. I can even understand people in noisy restaurants. The Enhanced DeNoise Mode is `,
      content: `I love my Orka hearing aids. They have made a tremendous improvement in the clarity of people's voices, especially in noisy environments. I can even understand people in noisy restaurants. The Enhanced DeNoise Mode is magical in its ability to cut out background noise in environments such as in a car. As a birder, it is wonderful how much better I can hear birds now. The price is fantastic. The service is excellent. And Orka continues to make improvements in the app.`,
      author: `Paul`,
      stars: 5,
      id: '3'
    },
    {
      title: `The ORKA are very nice`,
      subTitle: `The ORKA are very nice, comes in a well packed box, contains all the components you need. They work well, but you need to keep your phone on you or really close by or you’ll lose the Bluetooth connection and they won’t work no more. They also  `,
      content: `The ORKA are very nice, comes in a well packed box, contains all the components you need. They work well, but you need to keep your phone on you or really close by or you’ll lose the Bluetooth connection and they won’t work no more. They also whistle allot if you where glass it can be annoying. I had to send mine back due to this.`,
      author: `Monica Holbrook`,
      stars: 3,
      id: '4'
    },
    {
      title: `This is a game-changer.`,
      subTitle: `Of all the hearing aids I have tested in the past Orka has the best look, clarity and performance of some of the most expensive hearing aids on the market. Your team sho uld take a bowl... I think I mentioned this before that Orka `,
      content: `Of all the hearing aids I have tested in the past Orka has the best look, clarity and performance of some of the most expensive hearing aids on the market. Your team sho uld take a bowl... I think I mentioned this before that Orka hearing aids are the only ones I have ever tested where musical instruments sound true to their acoustical sound quality.`,
      author: `David`,
      stars: 5,
      id: '5'
    }
  ],
  btn: 'View All Reviews',
  link: 'Reviews'
}
