import { Button, Container } from '@evocolabs/orkaui'
import Card, { CardInfo } from './Card'

import React from 'react'
import { SectionTitle } from '../title'
import { Stack } from '@mui/material'
import { cacheClick } from '@/pages/Home'
import styles from './styles.module.scss'
import { useRouter } from 'next/router'

const EXPLORE_BTN_TEXT = 'Explore Orka Two'
const CAPTION = 'What Makes Orka Two Different?'
const EXPLORE_PATH = '/OrkaTwo'

const cardData: CardInfo[] = [
  {
    src: 'denoise.jpg',
    title: 'Next Level Noise\nReduction with AI',
    content: `With Orka's proprietary AI DeNoise technology, no matter where you are, Orka Two strikes the perfect balance between speech clarity and noise reduction.`
  },
  {
    src: 'connectivity.jpg',
    title: 'Seamless Bluetooth Connectivity',
    content:
      'Compatibility with both iOS and Android, multi-device connectivity, and undistorted high quality audio streaming. Now you have them all.'
  }
]

const Difference = () => {
  const router = useRouter()

  return (
    <Container id="homepage-difference">
      <Stack spacing={{ xs: 4, dl: 2 }} alignItems="center">
        <SectionTitle>{CAPTION}</SectionTitle>
        <Stack direction={{ dl: 'row' }} spacing={{ xs: 4, dl: 5 }}>
          {cardData.map(({ src, ...info }) => (
            <Card src={src} {...info} key={info.title} />
          ))}
        </Stack>
        {/* button hidden on mobile view but show in <Services />*/}
        <Button
          id="homepage-difference-section-explore-button"
          className={styles.exploreCTA}
          size="large"
          onClick={() => {
            cacheClick('homepage-difference')
            router.push(EXPLORE_PATH)
          }}
        >
          {EXPLORE_BTN_TEXT}
        </Button>
      </Stack>
    </Container>
  )
}

export default Difference
