import { useState } from 'react'

import { Container } from '@evocolabs/orkaui'
import { Box } from '@mui/material'
import styles from './styles.module.scss'
import MissionCard, { MissionCardProps } from './MissionCard'
import { SectionTitle } from '../../title'
import FounderStory from './FounderStory'
import ProductStory from './ProductStory'

const data = {
  title: `Orka's Mission`,
  missionList: [
    {
      title: `Orka's Story`,
      src: 'ben.jpg',
      content:
        'In 2018 I was in my third year designing products for Apple. I could envision a future in Cupertino. Who knows, maybe I could be a part of the next iPad or Apple Watch...',
      ctaText: 'Read Full Story',
      tag: 'Co-Founder & CEO, Ben',
      modalType: 'founderStory'
    },
    {
      title: 'Tune Into the World',
      src: 'xinke.jpg',
      content:
        'The initial joy of owning a hearing aid unfortunately sometimes shifts into frustration due to outdated tech issues. But what if this scenario could take a different turn?',
      ctaText: 'Watch Full Introduction',
      tag: 'Co-Founder & CMO, Xinke',
      modalType: 'productStory',
      variant: 'video'
    }
  ] as (MissionCardProps & { modalType: string })[]
}

function Mission() {
  const [activeModal, setActiveModal] = useState<string>('')
  const handleClose = () => {
    setActiveModal('')
  }

  data.missionList[1].onPlay = () => {
    setActiveModal('productStory')
  }

  return (
    <>
      <Container className={styles['mission-container']}>
        <SectionTitle>{data.title}</SectionTitle>
        <Box className={styles['mission-content-wrapper']}>
          {data.missionList.map(({ title, modalType, ...props }) => (
            <MissionCard
              key={title}
              title={title}
              onClick={() => {
                setActiveModal(modalType)
              }}
              {...props}
            />
          ))}
        </Box>
      </Container>
      <FounderStory
        open={activeModal === 'founderStory'}
        onClose={handleClose}
      />
      <ProductStory
        open={activeModal === 'productStory'}
        onClose={handleClose}
      />
    </>
  )
}

export default Mission
