import { Box, Stack } from '@mui/material'

import { ContentTitle } from '../title'
import React from 'react'
import { Typography } from '@evocolabs/orkaui'
import { getImage } from './utils'
import styles from './styles.module.scss'

export interface CardInfo {
  src: string
  title: string
  content: string
}
const Card = ({ src, title, content }: CardInfo): JSX.Element => {
  return (
    <Stack
      direction="column"
      maxWidth="400px"
      spacing={{ xs: 2, dl: 4 }}
      flex={1}
      sx={{
        '& > img:first-of-type': {
          display: {
            xs: 'inline',
            md: 'none'
          }
        },
        '& > img:last-of-type': {
          display: {
            xs: 'none',
            md: 'inline'
          }
        }
      }}
    >
      <img
        src={
          src?.startsWith('http://') || src?.startsWith('https://')
            ? src
            : getImage(src, true)
        }
        className={styles.image}
        loading="lazy"
        alt={title}
      />
      <img
        src={
          src?.startsWith('http://') || src?.startsWith('https://')
            ? src
            : getImage(src, false)
        }
        className={styles.image}
        loading="lazy"
      />
      <Stack direction="column" spacing={2}>
        <Box p={{ xs: 0, dl: '0 32px' }} display="flex">
          <ContentTitle width="100%">{title}</ContentTitle>
        </Box>
        <Typography
          variant="body2"
          textAlign="center"
          margin={{ xs: '16px 0 0', dl: '16px 8px 0 !important' }}
        >
          {content}
        </Typography>
      </Stack>
    </Stack>
  )
}

export default Card
