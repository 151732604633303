import { SxProps } from '@mui/material'
import joinCDN from '@/src/utils/CDN'
const SRC_FOLDER_PATH = '/website/homepage'

export function getImage(src: string, devicePath: string): string
export function getImage(src: string, inMobileView: boolean): string
export function getImage(src: string, deviceOrFlag: string | boolean): string {
  const devicePath =
    typeof deviceOrFlag === 'string'
      ? deviceOrFlag
      : deviceOrFlag
      ? 'Mobile'
      : 'PC'

  return joinCDN(`${SRC_FOLDER_PATH}/${devicePath}/${src}`)
}

interface MediaInfo {
  src: string
  coverRatio: number // height / width
}

export interface ResponsiveBg<T = MediaInfo & SxProps> {
  pc: T
  mobile: T
  tablet?: T
}

export const getBackgroundStyles = (media: MediaInfo) => ({
  backgroundSize: 'cover',
  backgroundImage: `url(${media.src})`,
  paddingBottom: `${media.coverRatio * 100}%`
})

export const getResponsiveStyles = ({ pc, mobile, tablet }: ResponsiveBg) => ({
  ...getBackgroundStyles(mobile),
  '@media (min-width: 769px)': getBackgroundStyles(pc),
  '@media (min-width: 769px) and (max-width: 1024px)':
    !!tablet && getBackgroundStyles(tablet)
})
