import { Box, Dialog } from '@mui/material'
import { Button, Typography } from '@evocolabs/orkaui'
import Close24 from '@evocolabs/icons/lib/Close24'
import theme from '@/src/theme'
import { useRouter } from 'next/router'
import styles from './styles.module.scss'

interface ProductStoryProps {
  open: boolean
  onClose: () => void
}

const data = {
  title: 'Introducing Orka Two Hearing Aids',
  ctaText: 'Explore Orka Two',
  link: 'https://www.youtube.com/embed/BJNu8Sy0z8s?si=DItvtTV-PM6K8MjO'
}

function ProductStory({ open, onClose }: ProductStoryProps) {
  const router = useRouter()
  return (
    <Dialog
      open={open}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '12px',
          margin: '16px',
          width: '100%'
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <Box className={styles['product-story']}>
        <Typography
          variant="h2"
          sx={{
            fontSize: {
              xs: '24px',
              dl: '40px'
            },
            lineHeight: {
              xs: '32px',
              dl: '42px'
            }
          }}
        >
          {data.title}
        </Typography>
        <Box className={styles['video-container']}>
          <iframe
            src={data.link}
            title="Introducing Orka Two Hearing Aids"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            frameBorder="0"
            className={styles['responsive-iframe']}
          />
        </Box>
        <Button
          id="homepage-mission-section-explore-orka-two"
          onClick={() => {
            router.push('/OrkaTwo')
          }}
          sx={{ mt: 3, alignSelf: 'center' }}
        >
          {data.ctaText}
        </Button>
        <Box className={styles.close} onClick={onClose}>
          <Close24 replaceColor={[['#26201F', theme.palette.grey[100]]]} />
        </Box>
      </Box>
    </Dialog>
  )
}

export default ProductStory
