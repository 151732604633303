import { Box } from '@mui/material'

export default function TrustpilotStars({
  scope = 5,
  maxScode = 5,
  gap = '4px',
  sx = {},
  fun = () => void 0
}) {
  const inActiveStars = maxScode - scope
  return (
    <Box
      onClick={fun}
      display="flex"
      sx={{ '&>div+div': { ml: gap }, ...sx }}
      flexDirection="row"
    >
      {Array(scope)
        .fill(1)
        .map((_, index) => {
          return <SingleStar key={index} isActive />
        })}
      {Array(inActiveStars)
        .fill(1)
        .map((_, index) => {
          return <SingleStar key={index} isActive={false} />
        })}
    </Box>
  )
}

const SingleStar = function ({
  width = '36px',
  height = '36px',
  isActive = true,
  fillColor = '#12B883',
  revertColor = '#FAF7F3',
  inActiveColor = '#B8B6B5',
  inActiveRevertColor = '#FAF7F3'
}) {
  return (
    <Box width={width} height={height}>
      <svg
        width="36"
        height="37"
        viewBox="0 0 36 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="0.0391846"
          width="36"
          height="36"
          rx="8"
          fill={isActive ? fillColor : inActiveColor}
        />
        <path
          d="M18 2.03918L21.5922 13.0949H33.2169L23.8123 19.9277L27.4046 30.9835L18 24.1506L8.59544 30.9835L12.1877 19.9277L2.7831 13.0949H14.4078L18 2.03918Z"
          fill={isActive ? revertColor : inActiveRevertColor}
        />
      </svg>
    </Box>
  )
}

export { SingleStar }
