import { Typography, Modal } from '@evocolabs/orkaui'
import { Box } from '@mui/material'
import TrustpilotStars from '@/src/components/TrustpilotStars'

function PopupWindows({
  open = false,
  title = '',
  content = '',
  stars = 5,
  author,
  callback
}) {
  return (
    <Modal
      open={open}
      closable
      disableButtons
      onCancel={() => callback()}
      content={
        <Box>
          <TrustpilotStars scope={stars} />
          <Typography
            variant="t1"
            component="div"
            sx={{
              mt: '16px'
            }}
          >
            {title}
          </Typography>
          <Typography variant="body3" component="div">
            {content}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            mt="16px"
            justifyContent="space-between"
          >
            <Typography variant="Body3">{author}</Typography>
          </Box>
        </Box>
      }
    />
  )
}

export default PopupWindows
