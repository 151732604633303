import { Container, Typography } from '@evocolabs/orkaui'
import { ContentTitle, SectionTitle } from '../title'
import React, { useEffect } from 'react'

import { Box } from '@mui/material'
import Lottie from 'react-lottie'
import animateData from '@/src/lottie/homepage/guidinglines.json'
import joinCDN from '@/src/utils/CDN'
import { makeStyles } from '@mui/styles'
import theme from '@evocolabs/orkaui/lib/theme.cjs'
import { throttle } from 'lodash'

const useStyles = makeStyles(() => ({
  '@keyframes fadeInKF': {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  initFadeIn: {
    opacity: 0
  },
  initNoFade: {
    opacity: 1
  },
  fadeIn: {
    animationName: '$fadeInKF',
    animationDuration: '300ms',
    animationTimingFunction: 'linear',
    animationIterationCount: 1
  }
}))

const data = {
  title: 'How It Works with Orka',
  steps: [
    {
      src: joinCDN('/website/homepage/find.jpg'),
      title: 'Find Your Fit',
      subTitle:
        'Take a few minutes out of your day, answer a few questions online, and understand your unique hearing conditions.'
    },
    {
      src: joinCDN('/website/homepage/quick-delivery.jpg'),
      title: 'Quick Delivery & Setup',
      subTitle:
        'Your hearing aids will be delivered to your door, and a guided setup by your personal hearing care professional will ensure a smooth start.'
    },
    {
      src: joinCDN('/website/homepage/support.jpg'),
      title: 'Expert Remote Support',
      subTitle:
        'Count on our hearing care professionals for smooth and real-time online fine-tuning sessions, tailoring Orka Two to your personal needs.'
    }
  ],
  btn: 'Start Now',
  link: '/SurveyPage'
}

const ImageText = function ({ src, sn, title, subTitle, fadeIn = false }) {
  const classes = useStyles()
  const [classStyle, setClassStyle] = React.useState(
    fadeIn ? classes.initFadeIn : classes.initNoFade
  )
  const imgRef = React.useRef(null)
  useEffect(() => {
    if (!fadeIn) return
    const handleScroll = () => {
      const image = imgRef.current
      if (image) {
        // 获取图片距离顶部的距离
        const imageTop = image.getBoundingClientRect().top
        // 计算透明度，最小值为0，最大值为1
        if (
          imageTop <
          window.innerHeight - image.getBoundingClientRect().height / 3
        ) {
          setClassStyle(classes.fadeIn)
          window.removeEventListener('scroll', handleScroll)
        }
      }
    }
    // 添加滚动事件监听器
    window.addEventListener('scroll', handleScroll)
    // 在组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', dl: sn % 2 ? 'row' : 'row-reverse' }}
      marginTop={sn > 1 ? '40px' : 0}
      marginLeft="auto"
      marginRight="auto"
      maxWidth={{ xs: '400px', dl: 'initial' }}
      sx={{
        '>img': {
          width: {
            xs: '100%',
            dl: 568
          },
          height: {
            xs: 'auto',
            dl: 471
          },
          zIndex: 0,
          borderRadius: '12px'
        }
      }}
    >
      <img
        src={src}
        height="auto"
        ref={imgRef}
        className={classStyle}
        alt={title}
        loading='lazy'
      />
      <Box
        display="flex"
        flexDirection="column"
        width={{ xs: '100%', dl: 320 }}
        mr={{ xs: 0, dl: sn % 2 ? 0 : '64px' }}
        ml={{ xs: 0, dl: sn % 2 ? '64px' : 0 }}
        mt={{ xs: '-24px', dl: 0 }}
        alignItems={{ xs: 'center', dl: 'flex-start' }}
        justifyContent="center"
        zIndex={1}
      >
        <Box
          width={48}
          height={48}
          borderRadius="50%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="#FFFFFF"
          border="solid 2.5px #30445E"
        >
          <Typography variant="T2" color={theme.palette.primary[700]}>
            {sn}
          </Typography>
        </Box>
        <ContentTitle margin={{ xs: '8px 0', dl: '32px 0 8px' }}>
          {title}
        </ContentTitle>
        <Typography variant="body2" textAlign={{ xs: 'center', dl: 'start' }}>
          {subTitle}
        </Typography>
      </Box>
    </Box>
  )
}

function delayCallback(callback: Function, duration: number = 20) {
  if (duration < 50) {
    duration = 50
  }
  let start
  function animate(time) {
    if (!start) {
      start = time
    }

    if (time - start >= duration) {
      callback()
    } else {
      requestAnimationFrame(animate)
    }
  }
  requestAnimationFrame(animate)
}

/**
 * 上一次容器的偏移位置
 */
let preContainerOffset = 0
const playFromPosition = function (
  startupAnimate: Function,
  stopAnimate: Function
) {
  /** 动画时长一共3秒 */
  const process = 3000
  const container = document.querySelector('.image-text-container')
  if (!container) return
  const rect = container.getBoundingClientRect()
  const containerHeight = rect.height
  const containerTop = rect.top
  const windowHeight =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  let windowMidHeight = windowHeight / 2
  if (containerHeight > windowHeight) {
    windowMidHeight *= 2
  }
  const midContainerTop = containerTop - windowMidHeight
  // 容器未滑入到屏幕中心线
  if (midContainerTop > 0) {
    return
  }
  // 容器完全滑出超过屏幕中心线
  // if (-midContainerTop > containerHeight) {
  //   return
  // }
  const currentProcess = -midContainerTop / containerHeight
  const diffProcess = currentProcess - preContainerOffset / containerHeight
  // 向下滚动
  if (diffProcess > 0) {
    startupAnimate()
    delayCallback(
      () => stopAnimate(),
      (currentProcess / containerHeight) * process * 1000
    )
  } else {
    // 向上滚动
    // startupAnimate(-1)
    // delayCallback(() => stopAnimate(-1), diffProcess * process)
  }
  preContainerOffset = -midContainerTop
}

export default function HowItWork() {
  const defaultOptions = {
    loop: false,
    autoplay: false,
    animationData: animateData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }
  const [lottieState, setLottieState] = React.useState({
    isStopped: true,
    isPaused: true,
    direction: 1
  })

  const animateFun = () => {
    const isMobile = document.documentElement.clientWidth < 905
    if (isMobile) return
    playFromPosition(
      (direction = 1) => {
        setLottieState({
          isStopped: false,
          isPaused: false,
          direction
        })
      },
      (direction = 1) => {
        setLottieState({
          isStopped: false,
          isPaused: true,
          direction: direction
          // direction: lottieState.direction
        })
      }
    )
  }
  const handleScroll = throttle(animateFun, 100)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return (
    <Container id="homepage-howitwork">
      <Box width={{ xs: '100%', dl: 1224 }}>
        <SectionTitle margin={{ xs: '24px auto 32px', md: '24px auto' }}>
          {data.title}
        </SectionTitle>
        <Box
          position="relative"
          sx={{
            '&>div[aria-label="animation"]': {
              display: { xs: 'none', dl: 'block' }
            }
          }}
        >
          <Lottie
            options={defaultOptions}
            width={761}
            height={1424}
            direction={lottieState.direction}
            style={{
              position: 'absolute',
              transform: 'translateX(220px) translateY(132px)'
            }}
            isStopped={lottieState.isStopped}
            isPaused={lottieState.isPaused}
            eventListeners={[
              {
                eventName: 'complete',
                callback: () => {
                  window.removeEventListener('scroll', handleScroll)
                }
              }
            ]}
          />
          <Box position="relative" zIndex={1} className="image-text-container">
            <ImageText {...data.steps[0]} sn={1} />
            <ImageText {...data.steps[1]} fadeIn sn={2} />
            <ImageText {...data.steps[2]} fadeIn sn={3} />
          </Box>
        </Box>
        <Box
          m={{ xs: '32px auto 64px', md: '64px auto' }}
          display="flex"
          justifyContent="center"
        >
        </Box>
      </Box>
    </Container>
  )
}
