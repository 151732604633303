import { Box, Stack, StackProps } from '@mui/material'
import React from 'react'
import MoneyBack40 from '@evocolabs/icons/lib/MoneyBack40'
import InstallmentBill40 from '@evocolabs/icons/lib/InstallmentBill40'
import I3years from '@evocolabs/icons/lib/I3years40'
import { Button, Typography } from '@evocolabs/orkaui'
import theme from '@evocolabs/orkaui/lib/theme.cjs'
import { useRouter } from 'next/router'
import { cacheClick } from '@/pages/Home'
import styles from './styles.module.scss'

interface BannerData {
  Icon: (props) => JSX.Element
  title: string
  content: string
}

export interface ServicesProps {
  CTAText?: string
  pageId?: string
  CTAHref?: string
}

const iconStyles = { replaceColor: [['#26201F', 'white']], size: 56 }
const data: BannerData[] = [
  {
    Icon: MoneyBack40,
    title: '45-Day Risk Free Trial',
    content:
      'Full refund anytime within the 45-day trial period, no obligations.'
  },
  {
    Icon: InstallmentBill40,
    title: 'Flexible Financing',
    content: 'Available at $1,899, or as low as $80 /mo with Affirm or PayPal.'
  },
  {
    Icon: I3years,
    title: '3-Year Warranty',
    content:
      'Receive a brand new product for damages (not intentional) to your current device.'
  }
]
const GET_STARTED = 'Get Started'
const GET_STARTED_PATH = '/SurveyPage'
const EXPLORE_BTN_TEXT = 'Explore Orka Two'
const EXPLORE_PATH = '/OrkaTwo'

function Item({
  Icon,
  title,
  content,
  ...restProps
}: BannerData & StackProps): JSX.Element {
  return (
    <Stack
      direction="row"
      spacing={{ xs: 3, dl: 2 }}
      alignItems="center"
      {...restProps}
    >
      <Icon {...iconStyles} />
      <Stack spacing={1}>
        <Typography
          variant="T2"
          sx={{
            color: 'white',
            fontSize: {
              xs: '16px',
              dl: '24px'
            },
            lineHeight: {
              xs: '24px',
              dl: '32px'
            }
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body4"
          sx={{ fontSize: { xs: '16px', md: '14px' } }}
        >
          {content}
        </Typography>
      </Stack>
    </Stack>
  )
}

export function Services({
  CTAText = GET_STARTED,
  pageId = 'homepage',
  CTAHref = GET_STARTED_PATH
}: ServicesProps) {
  const router = useRouter()
  return (
    <Stack width="100%" id={`${pageId}-service`}>
      <Box
        className={styles['services-section-wrapper']}
        bgcolor={theme.palette.primary[800]}
      >
        <Stack
          direction={{ xs: 'column', dl: 'row' }}
          spacing={{ xs: 3, dl: 6 }}
          className={styles['services-content']}
        >
          <Stack
            direction={{ xs: 'column', dl: 'row' }}
            spacing={{ xs: 3, dl: 6 }}
            style={{ margin: '0 auto' }}
          >
            {data.map(({ title, ...props }) => (
              <Item key={title} title={title} flex="1 1 0" {...props} />
            ))}
          </Stack>
          <Box alignSelf="center">
            <Button
              id={`${pageId}-service-section-get-started-button`}
              variant="destructive"
              className={styles.CTA}
              size="large"
              onClick={() => {
                cacheClick(`${pageId}-service`)
                router.push(CTAHref)
              }}
            >
              {CTAText}
            </Button>
          </Box>
        </Stack>
      </Box>

      {pageId === 'explore-orka-two-page' ? null : (
        <Button
          onClick={() => {
            cacheClick(`${pageId}-service`)
            router.push(EXPLORE_PATH)
          }}
          variant="textprimary"
          icon
          size="small"
          className={styles.exploreCTA__mobile}
          id={`${pageId}-difference-section-explore-button`}
        >
          {EXPLORE_BTN_TEXT}
        </Button>
      )}
    </Stack>
  )
}

export default Services
