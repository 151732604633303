/***
 * @description: 插入script标签到指定节点。
 * 必须在浏览器环境下执行，如果在React中使用必须在useEffect函数中执行
 * @param 'defer' | 'async' | '' lazy
 */
const insertScript = ({
  src = '',
  id = '',
  content = '',
  lazy = 'defer',
  callback = () => {},
  insertTag = 'body'
}) => {
  if (!src && !content) {
    return console.error('Src or content is required')
  }
  if (!typeof document) {
    return console.error('insertScript must exec of browser environment')
  }
  const script = document.createElement('script')
  script.defer = lazy === 'defer'
  script.async = lazy === 'async'
  if (src) {
    script.src = src
  }
  if (id) {
    script.id = id
  }
  if (content) {
    // 本地脚本，需要自行添加回调函数到脚本内容中
    if (!src) {
      content += `;(${callback.toString()})();`
    }
    script.innerHTML = content
  }
  script.onload = callback
  script.onerror = (err) => console.error(err, 'script load error')
  const parentNode =
    typeof insertTag === 'string'
      ? document.querySelector(insertTag)
      : insertTag
  if (parentNode instanceof Node) {
    parentNode.appendChild(script)
  } else {
    return console.error('insertTag must be a string or a Node')
  }
}

export default insertScript
